// src/apiService.js

import axios from 'axios';
import storage from 'store'
import appConfig from "@/config/appConfig";
import Vue from "vue";

const apiService = {
    // 基础URL，根据实际情况设置
    baseURL: process.env.VUE_APP_API_URL || 'https://www.u4unlock.cn/userapi/',
    // baseURL: process.env.VUE_APP_API_URL || 'http://localhost:8181',
    // 请求实例
    axiosInstance: axios.create({
        //http://localhost:8181
        baseURL: 'https://www.u4unlock.cn/userapi/',
        // baseURL: 'http://localhost:8181',
        timeout: 5000, // 可选，设置超时时间
        headers: {
            'Content-Type': 'application/json',
            'iToken': ''
        },
    }),

    // 请求拦截器
    interceptors: {
        requestInterceptor: (config) => {
            // 在这里可以添加全局请求头、处理身份验证等操作
            // 确保 config.headers 存在
            config.headers = config.headers || {};
            const token = storage.get(appConfig.ACCESS_TOKEN_NAME)
            if (token && config.url !== '/generatePcQrloginUrl' &&  config.url !== '/pcQrlogin') { // 除登录接口外，添加token
                config.headers.iToken = token;
            }
            return config;
        },
        responseInterceptor: (response) => {
            // 在这里可以统一处理响应数据，如错误码检查、异常处理等
            // console.log("response",response)
            return response.data;
        }
    },

    // 封装GET请求
    get: async (url, params = {}) => {
        try {
            let config = {
                params,
            };

            config = apiService.interceptors.requestInterceptor(config);

            const response =  await apiService.axiosInstance.get(url, config)
            return apiService.interceptors.responseInterceptor(response);
        } catch (error) {
            console.error('GET请求失败:', error);
            if (error.response.status === 401 ) {//登录过期
                const path = storage.get(appConfig.LAST_ROUTER)
                localStorage.clear()
                if (path){
                    storage.set(appConfig.LAST_ROUTER,path)
                }
            }
            throw error;
        }
    },

    // 封装POST请求
    post: async (url, data = {}, config = {}) => {
        try {
            config.data = data;

            config = apiService.interceptors.requestInterceptor(config);

            const response = await apiService.axiosInstance.post(url, config.data, config)
            return apiService.interceptors.responseInterceptor(response);
        } catch (error) {
            Vue.prototype.$message.error(JSON.stringify(error,null, 2))
            console.error('POST请求失败:', error);
            if (error.response.status === 401 ) {//登录过期
                const path = storage.get(appConfig.LAST_ROUTER)
                localStorage.clear()
                if (path){
                    storage.set(appConfig.LAST_ROUTER,path)
                }
            }
            throw error;
        }
    },
};

export default apiService;

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      showOrderSearchDialog: false,
      showWorkOrderDialog: false,
      id: '',
      roleName: '',
      advancedUser: '',
      email: '',
      point: 0,
      appTitle: 'U4快查'
  },
  getters: {
    getAppTitle(state) {
      return state.appTitle;
    },
    getShowOrderSearchDialog(state) {
      return state.showOrderSearchDialog;
    },
    getShowWorkOrderDialog(state) {
      return state.showWorkOrderDialog;
    },
    getId(state) {
      return state.id;
    },
    getPoint(state){
      return state.point
    },
    getAdvancedUser(state){
      return state.advancedUser
    },
    getEmail(state){
      return state.email
    }
  },
  mutations: {
    setAppTitle(state, show) {
      state.appTitle = show
    },
    setShowOrderSearchDialog(state, show) {
      state.showOrderSearchDialog = show
    },
    setShowWorkOrderDialog(state, show) {
      state.showWorkOrderDialog = show;
    },
    setId(state, show) {
      state.id=show
    },
    setPoint(state, show) {
      state.point=show
    },
    setRoleName(state, show){
      state.roleName=show
    },
    setAdvancedUser(state, show){
      state.advancedUser=show
    },
    setEmail(state, show){
      state.email=show
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div>
    <el-button :icon="icon" class="left" @click="changeSide"/>
    <span class="right" style="width: 16rem">
            <div v-if="login">
<!--                <el-avatar icon="el-icon-user-solid" style="display: inline-block; float: left"/>-->
                <el-button type="primary" @click="loginUser()">登录</el-button>
            </div>
          <el-col v-if="!login" :span=14>
              <div class="user" style="float: left; width: 10rem">
                  <span>用户ID: {{this.$store.state.id}}</span>
                  <span class="ml-2" style="color: red;margin-left: 5px">{{this.$store.state.roleName}}</span>
              </div>
              <div class="user" style="float: left; width: 10rem">
                  <span>当前积分: ¥{{this.$store.state.point}} </span>
              </div>
          </el-col>

          <el-tooltip
              v-if="!login"
              class="item"
              effect="dark"
              content="用户中心"
              placement="top-end"
          >
                <el-button icon="el-icon-s-custom" circle @click="replace('user-info')"></el-button>
            </el-tooltip>

            <el-tooltip
                v-if="!login"
                class="item"
                effect="dark"
                content="退出登录"
                placement="top-end"
            >

                <el-button icon="el-icon-moon-night" circle @click="loginOut()"></el-button>
            </el-tooltip>
        </span>

  </div>
</template>

<script>
import {replace, loginOutUser} from "@/utils";
import apiService from "@/api/apiService";

export default {
  props: {
    icon: {
      type: String,
      default: 'el-icon-s-unfold'
    }
  },
  model: {
    event: 'collapse'
  },
  data() {
    return {
      user: null,
      login: true,
      searchNo: "",
      userBaseInfo: {
        userId: "USER1243",
        amount: 0,
      },
    };
  },
  created() {
    // getCurrentUser('header')
    this.user = JSON.parse(sessionStorage.getItem("userInfo"))
    if(this.user !== null){
      this.login = false;
    }else{
      this.login = true
      // loginOutUser();
    }
  },
  computed: {
    id() {
      return this.$store.state.id
    },
    roleName() {
      return this.$store.state.roleName
    },
    pointsBalance(){
      return this.$store.state.point
    }
  },
  methods: {
    replace,
    changeSide() {
      this.$emit('collapse')
    },
    loginOut() {
      this.$nextTick(function () {
        apiService.get("/login-out", {}).then(() => {
          loginOutUser()
          this.login = true
        })
      })
    },
    loginUser() {
      loginOutUser()
      this.$router.push({path: '/qrsceneLogin'})
    }
  }
};
</script>

<style scoped>
.left {
  left: 1rem;
  position: relative;
  display: inline-block;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  appearance: none;
}

.right {
  width: 12rem;
  right: 0;
  height: 3rem;
  /*display: flex;*/
  flex-wrap: wrap;
  position: absolute;
  display: inline-block;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  appearance: none;
}

.left:hover {
  border-color: #3b82f6;
}
</style>

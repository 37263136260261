<template>
    <el-container v-if="!isMobile">
        <el-header height="4.5rem" class="el-header">
            <HeaderBar @collapse='collapse' :icon="icon"/>
        </el-header>
        <el-container class="container">
            <el-aside class="aside" v-if="showAside">
                <NavBar :isCollapse="!showAside" @appCollapse="appCollapse"/>
            </el-aside>
            <el-main class="main">
                <router-view />
            </el-main>
        </el-container>
    </el-container>
    <!--  mobile agent  -->
    <el-container v-else>
        <el-header style="padding: 0 0;background: #e6f1f9;">
            <HeaderWx/>
        </el-header>
        <el-main class="wx-main" style="padding: .5rem 0">
            <router-view />
        </el-main>
        <el-main>
        </el-main>
    </el-container>
</template>

<script>
import NavBar from "@/components/home/NavBar.vue";
import HeaderBar from "@/components/home/HeaderBar.vue";
import HeaderWx from "@/components/home/HeaderWx.vue";
import {isMobile} from "@/utils";
import apiService from '@/api/apiService';
import appConfig from "@/config/appConfig";
import storage from "store";

export default {
    name: "HomeView",
    components: {
        HeaderWx,
        NavBar,
        HeaderBar,
    },
    data() {
        return {
            icon: 'el-icon-s-unfold',
            isApp: window.innerWidth <= 991,
            isMobile: false,
            showAside: true,
            asideStatus: true
        }
    },
    mounted() {
        apiService.get('/currentUserInfo').then(res=>{
            if (res.code === 0){
                storage.set(appConfig.APP_USER,res.data)
            }else {
                storage.set(appConfig.ACCESS_TOKEN_NAME,null)
            }

        }).catch(() => {})
        window.addEventListener('resize', this.handleResize);
        /* 屏幕过小, 刚加载时不显示侧边栏 */
        this.appCollapse();
        this.isMobile = isMobile();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        collapse(){
            this.asideStatus = !this.asideStatus
            if(this.asideStatus){
                setTimeout(()=>{
                    this.icon = 'el-icon-s-unfold'
                    this.showAside = true
                },10)
            }else{
                setTimeout(()=>{
                    this.icon = 'el-icon-s-fold'
                    this.showAside = false
                },10)
            }
        },
        handleResize() {
            this.isApp = window.innerWidth <= 991;
        },
        appCollapse() {
            if (this.isApp) {
                this.collapse();
            }
        }
    }
};
</script>

<style scoped>
.el-header {
    height: 5rem;
    position: relative;
    top: 0; /* 这会将元素与屏幕底部对齐 */
    left: 0; /* 如果需要元素从左边缘开始，可以设置此属性 */
    right: 0; /* 若要使元素宽度填满屏幕宽度，可以设置此属性 */
    z-index: 999; /* 可能需要设置较高的z-index确保它不会被其他元素覆盖 */
    box-sizing: border-box;

    background-color: var(--surface-card);
    transition: left 0.2s;
    width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;
}


.aside {
    position: relative;
    width: auto !important;
    height: calc(100vh - 6rem);
    /*z-index: 999;*/
    overflow-y: auto;
    -webkit-user-select: none;
    user-select: none;
    top: 1rem;
    left: 1rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;
}

.container {
    background: #eff3f8;
}

.main {
    position: relative;
    height: calc(100vh - 6rem);
}
.main div {
    height: 100%;
}

.el-aside {
    overflow: unset !important;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import copy from 'v-copy';

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import axios from 'axios';
import {getAppTitle} from "@/utils";

// 第二个axios实例，指向另一个后端地址
const secondaryAxios = axios.create({
    baseURL: 'https://www.u4unlock.cn/wxapi',
});

Vue.prototype.$axiosSecondary = secondaryAxios;

Vue.prototype.$message = ElementUI.Message;
Vue.use(ElementUI);
Vue.use(Element, { size: "small", zIndex: 3000 });
Vue.use(copy);

getAppTitle().then(title => {
    store.commit('setAppTitle', title);
}).finally(() => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
})


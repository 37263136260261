<template>
    <el-menu
        background-color="#ffffff"
        text-color="#495057"
        active-text-color="#3B82F6"
        :default-active="$route.path"
        class="nav"
        router
    >
        <el-menu-item
            v-for="item in navList"
            :key="item.name"
            :index="item.url"
            @click="afterClick"
        >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
        </el-menu-item>
    </el-menu>
    <!-- </el-card> -->
</template>

<script>
export default {
    model: {
      event: ['appCollapse']
    },
    data() {
        return {
            navList: [
                {
                    name: "服务列表",
                    icon: "el-icon-notebook-1",
                    url: "/service",
                },
                {
                    name: "提交订单",
                    icon: "el-icon-sell",
                    url: "/create",
                },
                {
                    name: "订单记录",
                    icon: "el-icon-shopping-cart-2",
                    url: "/order-log",
                },
                {
                    name: "自助充值",
                    icon: "el-icon-bank-card",
                    url: "/charge",
                },{
                name: "自助提现",
                icon: "el-icon-bank-card",
                url: "/withdrawal",
                },
                {
                    name: "联系客服",
                    icon: "el-icon-message",
                    url: "/contact",
                },
                // {
                //     name: "工单列表",
                //     icon: "el-icon-document-copy",
                //     url: "/work-list",
                // },
                // {
                //     name: "账单列表",
                //     icon: "el-icon-money",
                //     url: "",
                // },
                {
                    name: "余额变更记录",
                    icon: "el-icon-s-operation",
                    url: "/points-log",
                },
                {
                    name: "批量工具",
                    icon: "el-icon-s-cooperation",
                    url: "/batchQueryTool",
                },
                // {
                //     name: "批量查询工具",
                //     icon: "el-icon-link",
                //     url: "",
                // },
            ],
        };
    },
    methods: {
        afterClick() {
            this.$emit('appCollapse')
        }
    },
};
</script>

<style scoped>
.nav {
    /*transition: transform 0.2s, left 0.2s;*/
    background-color: var(--surface-overlay);
     border-radius: 12px;
    border: none;
    /* box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014; */
}

.nav .el-menu-item {
    text-align: left;
    color: #303133;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: 12px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import {getCurrentUser, isWx} from "@/utils";
import storage from 'store'
import appConfig from "@/config/appConfig";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: false, title:  " | 服务列表" },
        redirect: "/service",
        children:[{
            path: "/charge",
            name: "ReCharge",
            component: () =>
                import("../views/charge/ReCharge.vue"),
            meta: { requiresAuth: true, title: " | 积分充值"}
        }, {
                path: "/withdrawal",
                name: "Withdrawal",
                component: () =>
                    import("../views/pointsLog/Withdrawal.vue"),
                meta: { requiresAuth: true, title: " | 积分提现"}
            },{
            path: "/create",
            name: "Create",
            component: () =>
                import("../views/order/Create.vue"),
            meta: { requiresAuth: true, title: " | 提交订单" }
        },{
            path: "/work-list",
            name: "WorkOrderList",
            component: () =>
                import("../views/workorder/WorkOrderList.vue"),
            meta: { requiresAuth: true, title: " | 工单列表" }
        },{
            path: "/service",
            name: "Service",
            component: () =>
                import("../views/service/Service.vue"),
            meta: { requiresAuth: false, title: " | 服务列表" }
        },
        {
            path: "/service-info",
            name: "ServiceInfo",
            component: () =>
                import("../views/service/ServiceInfo.vue"),
            meta: { requiresAuth: true, title: " | 服务描述" }
        },{
            path: "/contact",
            name: "Contact",
            component: () =>
                import("../views/contact/Contact.vue"),
            meta: { requiresAuth: false, title: " | 联系客服" }
        },{
            path: "/order-log",
            name: "OrderLog",
            component: () =>
                import("../views/order/OrderLog.vue"),
            meta: { requiresAuth: true, title: " | 历史订单" }
        },{
            path: "/points-log",
            name: "PointsLog",
            component: () =>
                import("../views/pointsLog/PointsLog.vue"),
            meta: { requiresAuth: true, title: " | 余额变更记录"}
        }]
    },
    {
        path: "/user-info",
        name: "user-info",
        component: () =>
            import("../views/userInfo/UserInfo.vue"),
        meta: { requiresAuth: true, title: " | 个人中心" }
    },{
        path: "/wechatAuth",
        name: "wechatAuth",
        component: () =>
            import("../views/wechatAuth/WechatAuth.vue"),
        meta: { requiresAuth: false ,isLoginPage:true, title: " | 微信登录"}
    },{
        path: "/qrsceneLogin",
        name: "qrsceneLogin",
        component: () =>
            import("../views/wechatAuth/QrsceneLogin.vue"),
        meta: { requiresAuth: false ,isLoginPage:true}
    },{
        path: "/cv",
        name: "cv",
        component: () =>
            import("../views/Cv.vue"),
        meta: { title: " | 扫一扫"}
    },{
        path: "/register",
        name: "register",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/wechatAuth/Register.vue"),
        meta: { requiresAuth: false ,isLoginPage:true, title: " | 用户注册"}
    },{
        path: "/mailRegister",
        name: "mailRegister",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/wechatAuth/MailRegister.vue"),
        meta: { requiresAuth: false ,isLoginPage:true, title: " | 邮箱验证"},
        beforeEnter: (to, from, next) => {
            let windowType = window.performance.getEntries()[0].type
            // let windowType = window.performance.navigation.type
            // windowType = 0, 首次进入
            // windowType = 1, 页面刷新
            console.log(from.name)
            if((windowType !== 'navigate' || windowType !== 'reload')  && (from.name === undefined || from.name === null)) {
                next('/register')
            } else {
                next()
            }
        }
    },
    {
        path: "/batchQueryTool",
        name: "batchQueryTool",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/batchQueryTool/BatchQueryTool.vue"),
        meta: { requiresAuth: true, title: " | 批量查询工具"}
    },
    {
        path: "/order-info",
        name: "OrderInfo",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/order/OrderInfo.vue"),
        meta: { requiresAuth: true, title: " | 订单详情"}
    },
    {
        path: "/login-authorize",
        name: "LoginAuthorize",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/LoginAuthorize.vue"),
        meta: { requiresAuth: false, title: " | 登录授权"}
    },
    {
        path: "*",
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
        meta: { requiresAuth: false, title: " | 404"}
    }
];

const router = new VueRouter({
    mode: "history", // 或者 'hash'
    base: process.env.BASE_URL,
    routes,
});

const scaleName = ['batchQueryTool'];

/* 手机上的路由守卫, 将历史浏览记录置空, 这样微信底部就不会显示左右按钮发生遮挡 */
if (isWx()) {
    router.beforeEach((to, from, next) => {
        document.title = to.meta.title?(store.state.appTitle + to.meta.title) : store.state.appTitle;
        const requiresAuth = to.meta.requiresAuth;
        const isLoginPage = to.meta.isLoginPage;
        const token = storage.get(appConfig.ACCESS_TOKEN_NAME)
        getCurrentUser()

        if (scaleName.includes(from.name)){
            removeScaleMeta()
        }

        // fixme 客户要求保留历史
        //  window.history.replaceState(null, null, window.location.href);

        //如果存在token，并且是访问登录，则直接重定向到首页
        if (token && isLoginPage){
            return  next()
        }

        if (requiresAuth === undefined){
            return  next({name:'wechatAuth',query: {go: to.path}})
        }

        if (requiresAuth){
            if (!token){
                storage.set(appConfig.LAST_ROUTER,to.path)
                return  next({name:'wechatAuth',query: {go: to.path}})
            }else {
                return next()
            }
        }
        return next();
    })
}
else {
    /* 电脑上的路由权限 */
    router.beforeEach((to, from, next) => {
        document.title = to.meta.title?(store.state.appTitle + to.meta.title) : store.state.appTitle;
        const requiresAuth = to.meta.requiresAuth;
        const isLoginPage = to.meta.isLoginPage;
        const token = storage.get(appConfig.ACCESS_TOKEN_NAME)
        getCurrentUser()

        //如果存在token，并且是访问登录，则直接重定向到首页
        if (token && isLoginPage){
            return  next({name:'home'})
        }

        if (requiresAuth === undefined){
            Vue.prototype.$message.error('登录状态过期, 请重新登录');
            return  next({name:'qrsceneLogin'})
        }

        if (requiresAuth){
            if (!token && from.name !== 'register'){
                storage.set(appConfig.LAST_ROUTER,to.path)
                Vue.prototype.$message.error('登录状态过期, 请重新登录');
                return  next({name:'qrsceneLogin'})
            }else {
                return next()
            }
        }
        return next()
    })
}


function removeScaleMeta() {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no');
    }
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
};

export default router;


import storage from "store";
import appConfig from "@/config/appConfig";
import apiService from "@/api/apiService";
import store from "@/store/index";

export function isMobile () {
    return navigator.userAgent.match(/(Android|iPhone|SymbianOS|Windows Phone|iPad|iPod|MicroMessenger)/i);
}

export function isWx () {
    return navigator.userAgent.match(/MicroMessenger/i);
}

export function replace(route) {
    // fixme 客户要求保留历史
    //  return this.$router.replace(route)
    return this.$router.push(route)
}

export function groupByParent(originArray) {
    const groupedOptions = [];
    // 遍历原始数组
    originArray.forEach(item => {
        if (!groupedOptions[item.parent]) {
            // 如果没有对应的parent，则创建新的对象并初始化options数组
            groupedOptions[item.parent] = {parent: item.parent, options: []};
        }
        item.label = '#' + item.serviceId + ' ' + item.serviceName + ' 价格' + item.price + ' 预计' + item.time
        // 将当前项添加到对应parent的options数组中
        groupedOptions[item.parent].options.push(item);
    });
    // 将分组后的对象转换为数组形式
    return Object.values(groupedOptions);
}

export function errorMsg(res) {
    if (res.msg) {
        return res.msg.split('[')[0]
    }
    return '操作失败'
}
export function getUser() {
    const user = storage.get(appConfig.APP_USER)
    return user
}
export function loginOutUser(){
    localStorage.clear()
    store.state.id = ''
    store.state.roleName = ''
    store.state.point = 0.00
    store.state.advancedUser = ''
    store.state.email = ''
    store.state.entList = []
}
// eslint-disable-next-line no-unused-vars
export async function getCurrentUser(from) {
    await apiService.get('/currentUserInfo').then(res => {
        if (res.code === 0) {
            storage.set(appConfig.APP_USER, res.data)
            store.state.id = res.data.id
            store.state.roleName = res.data.roleName
            store.state.point = res.data.pointsBalance
            store.state.advancedUser = res.data.advancedUser
            store.state.email = res.data.email
            store.state.entList = res.data.entList
            const userInfo = {
                id: res.data.id,
                roleName: res.data.roleName,
                pointsBalance: res.data.pointsBalance,
                advancedUser: res.data.advancedUser,
                email: res.data.email,
                entList: res.data.entList
            }
            sessionStorage.setItem(`userInfo`, JSON.stringify(userInfo))
            // this.$store.commit('point', res.data.pointsBalance);
        }
    }).catch(() => {
        sessionStorage.setItem(`userInfo`, null)
    })
}

export async function getAppTitle() {
    return await apiService.get('/appTitle/title').then(res => {
        if (res.code === 0) {
            return res.data
        }else {
            return 'U4快查'
        }
    }).catch(()=>{
        return 'U4快查'
    })
}

export function changeToBlob(dataURL) {
    var arr = dataURL.split(","),
        type = arr[0].match(/:(.*?);/)[1],//获取MIME 类型，即image/png
        bstr = atob(arr[1]),
        count = bstr.length,
        u8arr = new Uint8Array(count);
    while (count--) {
        u8arr[count] = bstr.charCodeAt(count);
    }
    return new Blob([u8arr], {
        type: type,
    });
}


export function formatDateTime(date) {
    let year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `${year}-${pad(month)}-${pad(day)} ${pad(hour)} ${pad(minute)} ${pad(second)}`;
}

const stateType = {
    0: '',1:'info',2:'success',3:'warning',4:'danger'
}

export function stateColor(stateCode) {
    return stateType[stateCode];
}

const statsOptions = [
        {value: 0, label: '等待接受'},
        {value: 1, label: '正在处理'},
        {value: 2, label: '完成'},
        {value: 3, label: '拒绝'},
        {value: 4, label: '失败'}
    ]

export function stateMapping(stateCode) {
    for (const index in statsOptions) {
        if (statsOptions[index].value === stateCode) {
            return statsOptions[index].label
        }
    }
    return '未知'
}

export function getStatsOptions(){
    return statsOptions;
}

export function pad(num) {
    return num.toString().padStart(2, '0');
}


export function isValidIMEI(imei) {
    // 正则表达式匹配15位或17位数字的IMEI
    const imeiRegex = /^[\d]{15}(?:[\d]{2})?$/;
    return imeiRegex.test(imei);
}

export function isValidSN(sn) {
    // 正则表达式匹配一般格式的SN码，允许字母、数字及可选的分隔符"-_"，这里假设至少一个字母或数字
    const snRegex = /^[A-Z0-9]{10}$/;
    return snRegex.test(sn);
}

export function extractModelFromResult(result) {
    var modelRegex = /型号:\s*(.*?)\s*<br>/;
    var match = modelRegex.exec(result);

    if (match && match[1]) {
        let arr = match[1].split(' ')
        arr.splice(arr.length - 1,1)
        return  arr.join(' ');
    } else {
        return ''
    }
}

const schemas = [
    // {
    //     disabled: true,
    //     isCheck: true,
    //     fixed:true,
    //     label: "id",
    //     prop: "id"
    // },
    // {
    //     disabled: true,
    //     isCheck: true,
    //     fixed:true,
    //     label: "机型",
    //     prop: "model",
    //     isHtml:false
    // },
    {
        disabled: false,
        isCheck: true,
        fixed:true,
        width: "150px",
        label: "IMEI/SN",
        prop: "imei",
        filters:[],
        isHtml:false
    },
    // {
    //     disabled: true,
    //     isCheck: true,
    //     fixed:true,
    //     label: "服务",
    //     prop: "serviceName",
    //     filters:[],
    //     isHtml:false
    // },
    // {
    //     disabled: true,
    //     isCheck: true,
    //     fixed:true,
    //     label: "价格",
    //     prop: "price",
    //     isHtml:false
    // },
    {
        disabled: true,
        isCheck: true,
        fixed:true,
        label: "状态-U",
        prop: "state",
        filters:[],
        isHtml:false
    },
    // {
    //     disabled: true,
    //     isCheck: true,
    //     fixed:true,
    //     label: "原始结果",
    //     prop: "result",
    //     isHtml:true,
    //     width:'200px'
    // },
    {
        disabled: true,
        isCheck: true,
        fixed:true,
        label: "创建时间",
        prop: "createTime",
        isHtml:false
    },
]

export function getSchemas() {
    return JSON.parse(JSON.stringify(schemas))
}

export async function fetchAllData (url, params) {
    let allData = [];
    let page = 1;
    let hasMore = true;
    let maxPageSize = 200;
    let obj = Object.assign({}, params)
    obj.pageSize = maxPageSize;
    while (hasMore) {
        try {
            obj.page = page;
            const response = await apiService.post(url, obj);
            const dataPage = response.data?.dataList; // 假设data包含了分页数据列表

            // 如果数据为空或到达最后一页
            if (!dataPage || dataPage.length === 0) {
                hasMore = false;
            } else {
                allData = allData.concat(dataPage);
                // 最后一页不满一页， 也可以停止了
                if (dataPage.length < maxPageSize) {
                    hasMore = false;
                }
                page++;

            }
        } catch (error) {
            console.error(`Error fetching page ${page}:`, error);
            hasMore = false;
        }
    }
    return allData;
}

export function hasEnt(ent) {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    if(userInfo !== null) {
        return userInfo.entList.includes(ent)
    }
    return false
}

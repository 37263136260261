<template>
    <el-row style="width: 100vw !important;padding: 0 5px">
        <el-col :span=12>
            <div class="user">
                <span>用户ID: {{this.$store.state.id}}</span>
                <span class="ml-2" style="color: red;margin-left: 5px">{{this.$store.state.roleName}}</span>
            </div>
            <div class="user">
                <span>当前积分: ¥{{this.$store.state.point}} </span>
            </div>
        </el-col>
        <el-col :span=12>
            <el-button-group style="float: right;padding-top: 8px;">
                <el-button v-if="showConcat && showConcatEnt" class="h-button" type="danger" size="mini" @click="replace('work-list')">
                    意见反馈
                </el-button>
                <el-button v-if="showCharge && showChargeEnt" class="h-button" type="primary" size="mini" @click="replace('charge')">
                    充值积分
                </el-button>
                <el-button v-if="showConcat && showConcatEnt" class="h-button" type="warning" size="mini" @click="replace('contact')">
                    联系客服
                </el-button>
                <el-button v-if="showHisOrder && showHisOrderEnt" class="h-button" type="success" size="mini" @click="replace('order-log')">
                    历史订单
                </el-button>
                <el-button v-if="showSearchOrder && showSearchOrderEnt" class="h-button" type="primary" size="mini" @click="searchOrder">
                    搜索订单
                </el-button>
                <el-button v-if="showRefresh" class="h-button" type="success" size="mini" @click="refresh">
                    刷新页面
                </el-button>
                <el-button v-if="showVip" class="h-button" type="danger" size="mini" @click="replace('user-info')">会员中心
                </el-button>
                <el-button v-if="showHome" class="h-button" type="warning" size="mini" @click="replace('service')">返回主页
                </el-button>
                <el-button v-if="showClose" class="h-button" type="danger" size="mini" @click="closePage">关闭页面
                </el-button>

            </el-button-group>
        </el-col>
    </el-row>
</template>
<script>

import {getUser, replace, hasEnt} from "@/utils";
import wx from "weixin-js-sdk";

export default {
    data() {
        return {
            user:null,
            showCharge: true,
            showConcat: false,
            showHisOrder: true,
            showSearchOrder: false,
            showRefresh: false,
            showVip: true,
            showHome: false,
            showClose: false,

            showChargeEnt: true,
            showConcatEnt: false,
            showHisOrderEnt: true,
            showSearchOrderEnt: false,
            showRefreshEnt: false,
            showVipEnt: true,
            showHomeEnt: false,
            showCloseEnt: false,
        }
    },
    watch : {
      $route(to) {
          /* 监听路由变化 */
          this.changeHeader(to.path);
      }
    },
    mounted() {
        /* 页面刷新, 重新构建header */
        this.user = getUser()
        this.changeHeader(this.$route.path)
        this.initEnt()
    },
    created(){
      this.user = getUser()
    },
    methods: {
        replace,
        initEnt(){
          this.showChargeEnt = hasEnt('AP_USER_PAY')
          this.showConcatEnt = hasEnt('AP_USER_CUSTOMER')
          this.showHisOrderEnt = hasEnt('AP_USER_ORDER')
          this.showSearchOrderEnt = hasEnt('AP_USER_SEARCH_ORDER')
        },
        changeHeader(toPath) {
            this.reset();
            switch(toPath) {
                case '/charge':
                    this.charge();break;
                case '/work-list':
                    this.charge();break;
                case '/order-log':
                    this.hisOrder();break;
                case '/contact':
                    this.concat();break;
                case '/create':
                    this.createOrder();break;
                case '/service-info':
                    this.serviceInfo();break;
                case '/withdrawal':
                    this.withdrawal();break;
                case '/':
                case '/service':
                    this.home();break;
            }
        },
        reset() {
            this.showCharge = false;
            this.showConcat = false;
            this.showHisOrder = false;
            this.showSearchOrder = false;
            this.showRefresh = false;
            this.showVip = false;
            this.showHome = false;
            this.showClose = false;
        },
        serviceInfo() {
            this.showCharge = true;
            this.showClose = true;
        },
        createOrder(){
          this.showCharge=true;
          this.showHisOrder=true;
          this.showHome=true;
        },
        charge() {
            this.showConcat = true
            this.showHome = true
        },
        concat() {
            this.showHome=true
        },
        hisOrder() {
            this.showSearchOrder=true
            this.showRefresh=true
            this.showHome=true

        },
        searchOrder() {
            // 显示搜索弹窗
            this.$store.commit('setShowOrderSearchDialog', true)
        },
        withdrawal(){
          this.showHome = true;
          this.showVip = true;
        },
        refresh() {
            this.$router.go(0)
        },
        closePage() {
            wx.closeWindow();
        },
        home() {
            this.showCharge = true;
            this.showHisOrder = true;
            this.showVip = true;
        },
    }
}
</script>
<style>
.h-button {
    padding: 5px 7px !important;
}

.user {
    font-size: 14px;
    font-weight: 600 !important;
    --inline-spacing: .5rem;
    --border-radius: 6px;
    --focus-ring: 0 0 0 .2rem #BFDBFE;
    --maskbg: rgba(0, 0, 0, .4);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    user-select: none;
    padding-top: 4px;
    padding-left: 0;
    float: left;
    width: 100%;
    display: flex;
}
</style>
